@import "../../../../../../assets/scss/variable.scss";

.leading-table-container-wrap {
  .leading-select-wrap {
    padding: 10px;
    display: flex;
    align-items: center;
    column-gap: 4px;

    @media (max-width: 1080px) {
      flex-direction: column;
      row-gap: 10px;
    }

    .league-table-select {
      width: 55%;

      @media (max-width: 1080px) {
        width: 100%;
      }
    }

    .season-table-select {
      width: 45%;

      @media (max-width: 1080px) {
        width: 100%;
      }
    }
  }

  .select__control {
    background-color: $color-Light-grey;
    border-radius: 8px;
    max-height: 45px;
    max-width: 400px;

    .select__single-value {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__placeholder {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__input-container {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__indicator-separator {
      width: 0px;
    }

    .select__dropdown-indicator {
      padding: 10px;
    }
  }

  .select__menu-portal {
    z-index: 99;
  }

  .select__control,
  .react-select__control {
    &.select__control--is-disabled {
      // border-color: $border-color;
      .select__indicator-separator {
        //   background-color: $border-color;
      }
    }

    // &.select__control--is-focused,
    // &.react-select__control--is-focused {

    // }

    &.select__control--menu-is-open {
      box-shadow: none;
      // border: none;
    }

    .select__indicator svg {
      cursor: pointer;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__single-value {
      // border: none;
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      outline: none;
      cursor: pointer;
      margin: 0px;
      font-family: "Inter", sans-serif;
    }

    .select__value-container {
      // border: none;
      padding: 0px;
      margin: 0px;

      .select__input-container {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        // margin-left: 10px;
      }
    }

    .select__placeholder {
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      cursor: pointer;
      font-family: $regulerFont;
    }
  }

  .select__menu {
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    z-index: 999;
  }

  .select__menu-list {
    padding: 0px;
  }

  // Select Menu
  .select__menu,
  .react-select__menu {
    .select__menu-list,
    .react-select__menu-list {
      .select__option,
      .react-select__option {
        cursor: pointer;
        font-size: 16px;
        color: $color-Black;
        font-family: $regulerFont;
        line-height: 19px;
        padding: 11px;

        &.select__option--is-focused {
          background-color: $color-grey;
          color: #000;
        }

        &.select__option--is-selected {
          background-color: $color-grey;
          color: $color-Black;
        }
      }
    }

    .select__menu-list,
    .react-select__menu-list {
      .select__group {
        .select__group-heading {
          margin-bottom: 0.5rem;
          color: green;
          font-weight: bolder;
          font-size: inherit;
        }
      }
    }
  }
}

.NRL-stepper {
  width: 50%;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }

  .stepper-input {
    display: flex !important;
    gap: 2px;
  }

  .stepper-input__button {
    min-width: 34px;
  }

  .MuiButton-icon {
    margin: 0px;
  }

  .MuiButtonBase-root {
    background: #e8eaec;
  }

  .tipping-select {
    @media (max-width: 1080px) {
      width: 100%;
    }
  }

  svg {
    path {
      stroke: $color-Black;
    }
  }
}

.leading-table-container-wrap .select__control {
  @media screen and (max-width: 1080px) {
    max-width: none !important;
  }
}
